import React, { useEffect, useState } from "react";
import {
  FaLeaf,
  FaLightbulb,
  FaLinkedin,
  FaList,
  FaMessage,
  FaPeopleGroup,
  FaPersonArrowUpFromLine,
} from "react-icons/fa6";
import {
  MdCancel,
  MdClose,
  MdPeople,
  MdPerson,
  MdPersonAdd,
  MdRocketLaunch,
} from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { RootState, ownerSelector, templateSelector } from "../../store/store";
import ownerSlice, { setNewCampaignData } from "../../store/slices/ownerSlice";
import { toast } from "react-toastify";
import { IoMdBulb, IoMdEye } from "react-icons/io";
import { FaPaperPlane } from "react-icons/fa";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import {
  createCampaign,
  createTemplate,
  launchCampaign,
  saveCampaign,
} from "../../store/features/ownerActions";
import { useNavigate } from "react-router-dom";
import {
  setSelectedTemplate,
  setTemplates,
} from "../../store/slices/templateSlice";
import { fetchProspects, fetchTemplates } from "../../utils";
import { BsClock, BsPerson } from "react-icons/bs";
import { Popover } from "antd";
import { checkPermission } from "../../utils";
import axios from "../../utils/axios";
import { CiLock } from "react-icons/ci";

type Props = {
  setShowExpiredAlert: any,
  onClose: () => void;
};

const CreateModal = (props: Props) => {
  const { templates, selectedTemplate, loading } =
    useSelector(templateSelector);
  const {
    newCampaignData,
    lists,
    owner,
    loading: campaignLoader,
  } = useSelector(ownerSelector);
  const [addingName, setAddingName] = useState(true);
  const [fetchingProspects, setFetchingProspects] = useState(false);
  const [addingProspects, setAddingProspects] = useState(false);
  const [prospectAdded, setProspectAdded] = useState(false);
  const [actionsAdded, setActionsAdded] = useState<string[]>([]);
  const [action, setAction] = useState("Invitation");
  const [addingDelay, setAddingDelay] = useState(false);
  const [delay, setDelay] = useState("1");
  const [addingMessage, setAddingMessage] = useState(false);
  const [onFocused, setOnFocused] = useState(false);
  const [selectingActions, setSelectingActions] = useState(false);
  const [addingInvitationNote, setAddingInvitationNote] = useState(false);
  const [showNoteInput, setShowNoteInput] = useState(false);
  const [selectingList, setSelectingList] = useState(false);
  const [selectedList, setSelectedList] = useState("");
  const [selectedProspects, setSelectedProspects] = useState<any[]>([]);
  const [showConnectedModal, setShowConnectedModal] = useState(false);
  const [connectedProspects, setConnectedProspects] = useState<any[]>([]);
  const dispatch: ThunkDispatch<RootState, undefined, Action> = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    // console.log(newCampaignData);
    // console.log(selectedTemplate);
    // console.log("Actions added", actionsAdded);
    dispatch(setSelectedTemplate(templates[0]));

    // dispatch(setNewCampaignData({ ownerId: owner._id }));
  }, [newCampaignData]);

  useEffect(() => {
    dispatch(
      setNewCampaignData({
        ...newCampaignData,
        campaignStatus: "ACTIVE",
        campaignName: "Invitation",
        ownerId: owner?._id,
        prospects: [],
        actions: [],
      })
    );
    setActionsAdded([]);
  }, [window.location.pathname]);
  const handleProspectfetch = async (listId: string) => {
    const loader = toast.loading("Fetching prospects...");
    try {
      setFetchingProspects(true);
      dispatch(setNewCampaignData({ ...newCampaignData, ownerId: owner?._id }));
      const getProspects = await fetchProspects(
        owner?._id as string,
        listId,
        1,
        3000
      );
      // console.log(getProspects);

      if (getProspects.inActiveCampaign) {
        toast.dismiss(loader);
        toast.error("Prospects are already in an active campaign!");
        return;
      }
      if (getProspects.prospects.length > 0) {
        dispatch(
          setNewCampaignData({
            ...newCampaignData,
            listId: listId,
            prospects:
              getProspects.prospects.length > 0 ? getProspects.prospects : [],
          })
        );
        const connected = getProspects.prospects.filter(
          (prospect: any) => prospect.connected === "ACCEPTED"
        );
        // console.log(connected);
        setConnectedProspects(connected);
        toast.dismiss(loader);
        toast.success("Prospects fetched successfully");
        setFetchingProspects(false);
      } else {
        toast.dismiss(loader);
        toast.error("No prospects in this list");
      }
    } catch (error) {
      toast.dismiss(loader);
      toast.error("Failed to fetch prospects");
    }
  };

  const checkExistingName = async () => {
    try {
      let res = await axios.post<{ isCampaignNameAvailable: Boolean }>(
        "/campaign/checkForCampaignName",
        {
          campaignName: newCampaignData.campaignName,
          ownerId: owner?._id,
        }
      );

      if (res.data.isCampaignNameAvailable) {
        if (newCampaignData.prospects.length === 0) {
          setAddingName(false);
          setAddingProspects(true);
          setSelectingList(true);
        } else {
          // setAddingProspects(false);
          setSelectingActions(true);
          setAddingName(false);
        }
        toast.success("Campaign name added successfully");
      } else {
        toast.error("Campaign name already exists");
      }
    } catch (error) {
      console.log(error);
      toast.error("Failed to add campaign name");
    }
  };

  const handleCampaignStart = async () => {
    // console.log(newCampaignData);

    if (actionsAdded.length === 0) {
      toast.error("Please add atleast one action");
      return;
    }

    if (newCampaignData.prospects.length === 0) {
      toast.error("Selected list have no prospects");
      return;
    }
    if (newCampaignData.actions.length === 0) {
      toast.error("Please add atleast one action");
      return;
    }
    const allowed = owner?._id ? await checkPermission(owner?._id) : true;
    if(!allowed){
      props.setShowExpiredAlert(true);
      return;
    }
    const loader = toast.loading("Creating your Campaign...");
    const reqData = {
      ...newCampaignData,
      ownerId: owner?._id,
      actions: newCampaignData.actions.filter(
        (action: any) => action.type !== "delay"
      ),
    };
    // console.log(reqData);
    try {
      const res = await dispatch(createCampaign(reqData));
      if (res.meta.requestStatus === "fulfilled") {
        toast.dismiss(loader);
        toast.success("Campaign Created");
        dispatch(
          setNewCampaignData({
            ownerId: owner?._id,
            campaignName: "Invitation",
            prospects: [],
            actions: [],
          })
        );
        navigate("/campaigns/list/running");
        return;
      }
      if (res.meta.requestStatus === "rejected") {
        toast.dismiss(loader);

        if (res.payload === "Request failed with status code 400") {
          toast.error("You can only run one campaign at a time");
        } else {
          toast.error("Failed to launch campaign");
        }
        // toast.error("Something went wrong");
      }
    } catch (error) {
      toast.dismiss(loader);
      toast.error("Something went wrong");
    }
  };

  const handleCampaignSave = async () => {
    // console.log(newCampaignData);

    if (actionsAdded.length === 0) {
      toast.error("Please add atleast one action");
      return;
    }

    if (newCampaignData.prospects.length === 0) {
      toast.error("Selected list have no prospects");
      return;
    }
    if (newCampaignData.actions.length === 0) {
      toast.error("Please add atleast one action");
      return;
    }
    const loader = toast.loading("Saving your Campaign...");
    const reqData = {
      ...newCampaignData,
      campaignStatus: "DRAFT",
      ownerId: owner?._id,
      actions: newCampaignData.actions.filter(
        (action: any) => action.type !== "delay"
      ),
    };
    // console.log(reqData);
    try {
      const res = await dispatch(saveCampaign(reqData));
      if (res.meta.requestStatus === "fulfilled") {
        toast.dismiss(loader);
        toast.success("Campaign saved as draft");
        dispatch(
          setNewCampaignData({
            ownerId: owner?._id,
            campaignName: "Invitation",
            prospects: [],
            actions: [],
          })
        );
        navigate("/campaigns/list/draft");
        return;
      }
      if (res.meta.requestStatus === "rejected") {
        toast.dismiss(loader);

        if (res.payload === "Request failed with status code 400") {
          toast.error("You can only run one campaign at a time");
        } else {
          toast.error("Failed to save campaign");
        }
        // toast.error("Something went wrong");
      }
    } catch (error) {
      toast.dismiss(loader);
      toast.error("Something went wrong");
    }
  };

  const handleTemplateSave = async () => {
    if (selectedTemplate.name === "" || selectedTemplate.content.body === "") {
      toast.error("Template name and body cannot be empty");
      return;
    }

    if (selectedTemplate.content.body.length > 8000) {
      toast.error("Message body cannot be more than 8000 characters");
      return;
    }

    const res = await dispatch(createTemplate(selectedTemplate));

    if (res.meta.requestStatus === "fulfilled") {
      toast.success("Template saved successfully");
      const templates = await fetchTemplates(owner?._id as string);
      dispatch(setTemplates(templates));
      dispatch(setSelectedTemplate(templates[0]));
      // setAddingMessage(false);
      // setSelectingActions(true);
    } else {
      toast.error("Error saving template");
    }
  };

  const handleActionRemove = (index: number, action: string) => {
    if (action === "Invitation" && actionsAdded.includes("Message")) {
      if (connectedProspects.length === 0) {
        toast.error("No connected prospects found. Remove message first");
        return;
      }
    }

    if (action === "Delay") {
      // console.log("delay");
      // setActionsAdded(actionsAdded.filter((action) => action !== "Delay"));
      setActionsAdded(
        actionsAdded.filter(
          (action) =>
            action !== "Message" && action !== "Visit" && action !== "Delay"
        )
      );
      toast.warning("Removing delay removes all actions after it");
      setAddingDelay(false);

      // remove any message with delay or visit with delay
      dispatch(
        setNewCampaignData({
          ...newCampaignData,
          actions: newCampaignData.actions.filter(
            (action: any) =>
              action.type !== "messageWithDelay" &&
              action.type !== "visitWithDelay"
          ),
        })
      );
      return;
    }

    const actions = newCampaignData.actions.filter(
      (action: any, i: number) => i !== index
    );
    setAddingMessage(false);
    setAddingDelay(false);
    setActionsAdded(actionsAdded.filter((action, i) => i !== index));
    dispatch(setNewCampaignData({ ...newCampaignData, actions: actions }));
  };

  return (
    <div className="w-full  flex justify-center text-xs items-center">
      <div className="bg-white h-[90vh] flex w-full rounded-lg p-4">
        <div className="border-r-2 noScroll flex h-90% overflow-y-scroll flex-col gap-2  items-center p-4 w-[30%]">
          <div
            onClick={() => {
              setAddingName(true);
              setSelectingList(false);
              setSelectingActions(false);
              setAddingMessage(false);
              dispatch(
                setNewCampaignData({
                  ...newCampaignData,
                  prospects: [],
                })
              );
              setAddingDelay(false);
            }}
            className="border-2 flex w-full justify-start gap-4 font-semibold items-center bg-mainColor p-2 rounded-md text-white cursor-pointer"
          >
            <FaLeaf size={30} />
            <span className="">{newCampaignData.campaignName}</span>
            {/* <span className="p-2 bg-white rounded-lg text-sm text-mainColor ml-auto">
              Draft
            </span> */}
          </div>
          {addingProspects && (
            <div
              onClick={() => {
                setAddingName(false);
                setAddingProspects(false);
                setSelectingList(true);
                setSelectingActions(false);
                setAddingDelay(false);
                setAddingMessage(false);
                // setActionsAdded([]);
                dispatch(
                  setNewCampaignData({
                    ...newCampaignData,
                    prospects: [],
                  })
                );
              }}
              className="border-2 cursor-pointer w-full flex justify-start gap-4 font-semibold items-center border-mainColor p-2 rounded-md hover:bg-mainColor hover:text-white transition-all ease-in-out duration-300"
            >
              <FaPeopleGroup size={30} />
              <span className="">Add prospects</span>
            </div>
          )}
          {/* {selectingActions && (
            <div className="border-2 w-full flex justify-start gap-4 font-semibold items-center border-mainColor p-4 rounded-md">
              <MdRocketLaunch size={30} />
              <span className="">Invitation</span>
              <span className="ml-auto p-1 text-white bg-mainColor/70 rounded-full cursor-pointer">
                <MdClose size={20} />
              </span>
            </div>
          )} */}
          {actionsAdded.length > 0 &&
            actionsAdded.map(
              (action, index) =>
                action !== "" && (
                  <div
                    key={Math.random()}
                    className="border-2 w-full flex justify-start gap-4 font-semibold items-center border-mainColor p-2 rounded-md hover:bg-mainColor hover:text-white transition-all ease-in-out duration-300"
                  >
                    {action === "Invitation" && <MdPersonAdd size={20} />}
                    {action === "Message" && <FaMessage size={20} />}
                    {action === "Visit" && <IoMdEye size={20} />}
                    {action === "Delay" && <BsClock size={20} />}

                    <span className="">{action}</span>
                    <Popover
                      content={`Click to remove ${action}`}
                      className="ml-auto  text-white bg-mainColor/70 rounded-full cursor-pointer"
                    >
                      <MdClose
                        onClick={() => handleActionRemove(index, action)}
                        size={20}
                      />
                    </Popover>
                  </div>
                )
            )}
        </div>
        {addingName && (
          <div className="p-4 flex w-full flex-col justify-center items-start">
            <h1 className="text-lg font-semibold">Name your campaign</h1>
            <div className="flex w-full gap-4 mt-4">
              <input
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    checkExistingName();
                  }
                }}
                value={newCampaignData.campaignName}
                onChange={(e) => {
                  dispatch(
                    setNewCampaignData({
                      ...newCampaignData,
                      campaignName: e.target.value,
                    })
                  );

                  // setProspectAdded(false);
                }}
                type="text"
                placeholder="Campaign Name"
                className="p-2 border-2 border-mainColor/20 w-[30%] outline-none rounded-md"
              />
              <button
                onClick={checkExistingName}
                className="bg-mainColor text-white p-2 px-4 rounded-md font-semibold"
              >
                Next
              </button>
            </div>
          </div>
        )}

        {selectingList && (
          <div className="p-4 flex w-full flex-col justify-center items-start">
            <div className="flex justify-center items-center gap-2">
              <span>
                <MdPeople size={25} />
              </span>
              <h1 className="text-lg font-semibold">
                Select a list to add prospects
              </h1>
            </div>
            <div className="flex w-full gap-4 mt-4">
              <select
                onChange={(e) => {
                  if (e.target.value === "") {
                    toast.error("Select a list to add prospects");
                  } else {
                    // console.log(e.target.value);
                    setSelectedList(e.target.value);
                    handleProspectfetch(e.target.value);
                  }
                }}
                className="p-2 border-2 border-mainColor/20 w-[30%] outline-none rounded-md"
                name="list"
                id="list"
              >
                <option value="" selected disabled>
                  Select a list
                </option>
                {lists.map((list: any) => {
                  if (
                    !list.inActiveCampaign &&
                    list.prospects.length > 0 &&
                    !list.isDeleted &&
                    list.listName !== "All Prospects"
                  ) {
                    return (
                      <option key={list._id} value={list._id}>
                        {list.listName}
                      </option>
                    );
                  } else {
                    return null;
                  }
                })}
              </select>
              <button
                disabled={fetchingProspects}
                onClick={() => {
                  if (selectedList === "") {
                    toast.error("Select a list to add prospects");
                    return;
                  }
                  if (
                    !newCampaignData.prospects ||
                    newCampaignData.prospects.length === 0
                  ) {
                    toast.error("No prospects in this list");
                    return;
                  }
                  // setAddingInvitationNote(true);
                  // dispatch(
                  //   setNewCampaignData({
                  //     ...newCampaignData,
                  //     actions: [{ type: "invitation" }],
                  //   })
                  // );
                  setSelectingList(false);
                  setSelectingActions(true);
                  setAddingProspects(true);
                }}
                className="bg-mainColor text-white p-2 px-4 rounded-md font-semibold"
              >
                Add
              </button>
            </div>
          </div>
        )}

        {addingInvitationNote && !showNoteInput && (
          <div className="p-4 flex w-full flex-col justify-center items-start">
            <h1 className="text-xl font-semibold">
              Would you like to add an Invitation note?
            </h1>
            <small>
              If you are not a Linkedin premium user, your invitation note can
              be only sent to 5 prospects
            </small>
            <div className="flex w-full gap-4 mt-4">
              <button
                onClick={() => {
                  setAddingInvitationNote(false);
                  setShowNoteInput(true);
                }}
                className="bg-mainColor text-white p-2 px-4 rounded-md font-semibold"
              >
                Yes
              </button>
              <button
                onClick={() => {
                  setAddingInvitationNote(false);
                  setSelectingActions(true);
                  setActionsAdded([...actionsAdded, "Invitation"]);
                  dispatch(
                    setNewCampaignData({
                      ...newCampaignData,
                      actions: [
                        ...newCampaignData.actions,
                        { type: "invitation", customMessage: "" },
                      ],
                    })
                  );
                }}
                className="bg-mainColor text-white p-2 px-4 rounded-md font-semibold"
              >
                No
              </button>
            </div>
          </div>
        )}

        {selectingActions &&
          !addingMessage &&
          !addingDelay &&
          !addingInvitationNote &&
          !showNoteInput && (
            <div className="p-4 flex w-full flex-col justify-center items-start">
              <h1 className="text-xl font-semibold">
                Add actions to your campaign!
              </h1>
              <div className="flex w-full gap-4 mt-4">
                <select
                  value={action}
                  onChange={(e) => {
                    setAction(e.target.value);
                  }}
                  className="p-2 border-2 border-mainColor/20 w-[30%] outline-none rounded-md"
                  name=""
                  id=""
                >
                  {!actionsAdded.includes("Invitation") &&
                    actionsAdded[0] !== "Message" && (
                      <option value="Invitation">Invitation</option>
                    )}
                  <option value="Visit">Visit</option>
                  <option value="Message">Message</option>
                  <option value="Delay">Delay</option>
                </select>
                <div className="flex gap-4 justify-center items-center">
                  <button
                    onClick={() => {
                      if (actionsAdded.length === 5) {
                        toast.error("You can only add 5 actions");
                        return;
                      }
                      if (action === "Invitation") {
                        setAddingInvitationNote(true);
                        setAction("Visit");
                      }
                      if (action === "Delay") {
                        // if (connectedProspects.length === 0) {
                        //   toast.error("Please add invitation first");
                        //   return;
                        // }
                        if (actionsAdded[actionsAdded.length - 1] === "Delay") {
                          toast.error("You can't add delay after delay");
                          return;
                        }
                        setActionsAdded([...actionsAdded, "Delay"]);
                        setAddingDelay(true);
                        setAction("Visit");
                      } else if (action === "Message") {
                        if (!actionsAdded.includes("Invitation")) {
                          if (connectedProspects.length === 0) {
                            toast.error(
                              "No connected prospects found! Add invitation first"
                            );
                            return;
                          } else {
                            setShowConnectedModal(true);
                          }
                        } else {
                          setActionsAdded([...actionsAdded, "Message"]);
                          setAddingMessage(true);
                          setAction("Visit");
                        }
                      } else if (action === "Visit") {
                        if (actionsAdded[actionsAdded.length - 1] === "Delay") {
                          // if (!actionsAdded.includes("Invitation")) {
                          //   toast.error("Please add invitation first");
                          //   return;
                          // }
                          setActionsAdded([...actionsAdded, action]);
                          dispatch(
                            setNewCampaignData({
                              ...newCampaignData,
                              actions: [
                                ...newCampaignData.actions,
                                {
                                  type: "visitWithDelay",
                                  delay:
                                    newCampaignData.actions[
                                      newCampaignData.actions.length - 1
                                    ].delay,
                                },
                              ],
                            })
                          );
                        } else {
                          setActionsAdded([...actionsAdded, action]);
                          dispatch(
                            setNewCampaignData({
                              ...newCampaignData,
                              actions: [
                                ...newCampaignData.actions,
                                { type: "visit" },
                              ],
                            })
                          );
                        }
                      }
                    }}
                    className="bg-mainColor text-white p-2 px-4 rounded-md font-semibold"
                  >
                    Add {action}
                  </button>
                  <p className="font-semibold">OR</p>
                  <button
                    disabled={campaignLoader}
                    onClick={handleCampaignStart}
                    className="bg-mainColor text-white p-2 px-4 rounded-md font-semibold"
                  >
                    Start Campaign
                  </button>
                  <p className="font-semibold">OR</p>
                  <button
                    disabled={campaignLoader}
                    onClick={handleCampaignSave}
                    className="border-2 border-mainColor text-mainColor p-2 px-4 rounded-md font-semibold"
                  >
                    Save as Draft
                  </button>
                </div>
              </div>
              {/* <div>
                <div className="border-2 flex w-full justify-start gap-4 font-semibold items-center text-sm mt-12 bg-mainColor/80 p-4 rounded-md text-white">
                  <FaLightbulb size={20} />
                  <span>
                    <p>Invitation is added by default</p>
                    <p>You can add more actions or start the campaign</p>
                  </span>
                </div>
              </div> */}
            </div>
          )}

        {addingMessage && (
          <div className="p-4 flex w-full  justify- items-start">
            <div className="flex flex-col gap-4 items-start justify-start w-[70%] border-r-2 px-4">
              <div className="flex justify-center items-center font-semibold gap-2">
                <span className="bg-mainColor p-2 text-white text-center rounded-lg">
                  <FaPaperPlane size={20} />
                </span>
                <p>Edit message</p>
              </div>
              <div className="flex gap-2 w-full">
                <input
                  value={selectedTemplate?.name || ""}
                  onChange={(e) => {
                    dispatch(
                      setSelectedTemplate({
                        ...selectedTemplate,
                        name: e.target.value,
                        content: {
                          ...selectedTemplate.content,
                          subject: e.target.value,
                        },
                      })
                    );
                  }}
                  type="text"
                  placeholder="Name your message template"
                  className="p-2 border-2 border-mainColor/20 w-full outline-none rounded-md"
                />
                <select
                  onChange={(e) => {
                    const selected = templates.find((template: any) => {
                      return template._id === e.target.value;
                    });
                    dispatch(setSelectedTemplate(selected));
                  }}
                  name=""
                  id=""
                  className="p-2 border-2 border-mainColor/20 w-full outline-none rounded-md"
                >
                  {templates.map((template: any) => (
                    <option key={template._id} value={template._id}>
                      {template?.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="bg-primary w-full h-[60vh] border-mainColor/50 border-2 rounded-lg relative">
                <p className="absolute right-1 text-xs top-1 ">
                  {selectedTemplate?.content.body.length || 0}/8000
                </p>
                <textarea
                  maxLength={8000}
                  id="message"
                  onClick={() => setOnFocused(true)}
                  value={selectedTemplate?.content.body || ""}
                  onChange={(e) => {
                    dispatch(
                      setSelectedTemplate({
                        ...selectedTemplate,
                        content: {
                          ...selectedTemplate.content,
                          body: e.target.value,
                        },
                      })
                    );
                  }}
                  className="outline-none text-start w-full h-full bg-primary p-2"
                  placeholder="Type your message here..."
                />
              </div>
              <div className="w-full flex justify-between">
                <select
                  onChange={(e) => {
                    // console.log(onFocused);
                    if (!onFocused) {
                      dispatch(
                        setSelectedTemplate({
                          ...selectedTemplate,
                          content: {
                            ...selectedTemplate.content,
                            body:
                              selectedTemplate.content.body +
                              " " +
                              e.target.value,
                          },
                        })
                      );
                    } else {
                      const message = document.getElementById("message") as any;
                      if (message) {
                        const start = message.selectionStart;
                        const end = message.selectionEnd;
                        const text = message.value;
                        const before = text.substring(0, start);
                        const after = text.substring(end, text.length);
                        const selectedText = text.substring(start, end);
                        message.value = before + e.target.value + after;
                        message.selectionStart = start + e.target.value.length;
                        message.selectionEnd = start + e.target.value.length;
                        dispatch(
                          setSelectedTemplate({
                            ...selectedTemplate,
                            content: {
                              ...selectedTemplate.content,
                              body:
                                before + e.target.value + after + selectedText,
                            },
                          })
                        );
                      }
                    }
                  }}
                  name=""
                  id=""
                  className="p-2 border-2 border-mainColor/20  outline-none rounded-md"
                >
                  <option value="Insert" disabled selected>
                    Insert a variable
                  </option>
                  <option value="{{firstName}}">{`{{firstname}}`}</option>
                  <option value="{{lastName}}">{`{{lastname}}`}</option>

                  <option value="{{company}}">{`{{company}}`}</option>
                  <option value="{{position}}">{`{{position}}`}</option>
                  <option value="{{location}}">{`{{location}}`}</option>
                </select>
                <div className="flex gap-2">
                  <button
                    disabled={loading}
                    onClick={handleTemplateSave}
                    className=" border-2 border-mainColor  p-2 px-4 rounded-md font-semibold text-mainColor"
                  >
                    Save Template
                  </button>
                  <button
                    onClick={() => {
                      setAddingMessage(false);
                      setSelectingActions(true);
                      if (
                        newCampaignData.actions[
                          newCampaignData.actions?.length - 1
                        ]?.type === "delay"
                      ) {
                        dispatch(
                          setNewCampaignData({
                            ...newCampaignData,
                            actions: [
                              ...newCampaignData.actions,
                              {
                                type: "messageWithDelay",
                                message: selectedTemplate.content,
                                delay:
                                  newCampaignData.actions[
                                    newCampaignData.actions.length - 1
                                  ].delay,
                              },
                            ],
                          })
                        );
                      } else {
                        dispatch(
                          setNewCampaignData({
                            ...newCampaignData,
                            actions: [
                              ...newCampaignData.actions,
                              {
                                type: "message",
                                message: selectedTemplate.content,
                              },
                            ],
                          })
                        );
                      }
                    }}
                    className="bg-mainColor text-white p-2 px-4 rounded-md font-semibold"
                  >
                    Add More
                  </button>
                </div>
              </div>
            </div>
            <div className="w-[30%] gap-2 flex flex-col px-4">
              <div className="border-2 flex w-full justify-start gap-4 font-semibold items-center text-white bg-mainColor p-4 rounded-md">
                <IoMdBulb size={30} />
                <span>Message Preview</span>
              </div>
              <div className="bg-primary w-full  border-mainColor/50 border-2 rounded-lg p-4 flex flex-col">
                <div className="flex w-full flex-col gap-4 items-start">
                  <div className="flex justify-center items-center gap-2">
                    <span className="p-1 text-mainColor bg-mainColor/20 rounded-lg">
                      <MdPerson size={30} />
                    </span>
                    <h1 className="font-semibold">John Doe</h1>
                  </div>
                  <div className="bg-white w-full h-full p-2 rounded-md text-sm">
                    <textarea
                      disabled
                      value={
                        selectedTemplate?.content.body
                          .replace("{{company}}", "Leadseeder")
                          .replace("{{position}}", "CEO")
                          .replace("{{location}}", "San Francisco, CA")
                          .replace("{{firstName}}", "John")
                          .replace("{{lastName}}", "Doe") || ""
                      }
                      placeholder="Subject"
                      className="outline-none text-xs noScroll resize-none bg-white  w-full h-32"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {showNoteInput && (
          <div className="p-4 flex w-full  justify- items-start">
            <div className="flex flex-col gap-4 items-start justify-start w-[70%] border-r-2 px-4">
              <div className="flex justify-center items-center font-semibold gap-2">
                <span className="bg-mainColor p-2 text-white text-center rounded-lg">
                  <FaPaperPlane size={20} />
                </span>
                <p>Edit message</p>
              </div>
              <div className="flex gap-2 w-full">
                <input
                  value={selectedTemplate?.name || ""}
                  onChange={(e) => {
                    dispatch(
                      setSelectedTemplate({
                        ...selectedTemplate,
                        name: e.target.value,
                        content: {
                          ...selectedTemplate.content,
                          subject: e.target.value,
                        },
                      })
                    );
                  }}
                  type="text"
                  placeholder="Name your message template"
                  className="p-2 border-2 border-mainColor/20 w-full outline-none rounded-md"
                />
                <select
                  onChange={(e) => {
                    const selected = templates.find((template: any) => {
                      return template._id === e.target.value;
                    });
                    dispatch(setSelectedTemplate(selected));
                  }}
                  name=""
                  id=""
                  className="p-2 border-2 border-mainColor/20 w-full outline-none rounded-md"
                >
                  {templates.map((template: any) => (
                    <option key={template._id} value={template._id}>
                      {template?.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="bg-primary w-full h-[60vh] border-mainColor/50 border-2 rounded-lg relative">
                <p className="absolute right-1 text-xs top-1 ">
                  {selectedTemplate?.content.body.length || 0}/300
                </p>
                <textarea
                  maxLength={300}
                  id="message"
                  onClick={() => setOnFocused(true)}
                  value={selectedTemplate?.content.body || ""}
                  onChange={(e) => {
                    dispatch(
                      setSelectedTemplate({
                        ...selectedTemplate,
                        content: {
                          ...selectedTemplate.content,
                          body: e.target.value,
                        },
                      })
                    );
                  }}
                  className="outline-none text-start w-full h-full resize-none bg-primary p-2"
                  placeholder="Type your message here..."
                />
              </div>
              <div className="w-full flex justify-between">
                <select
                  onChange={(e) => {
                    // console.log(onFocused);
                    if (!onFocused) {
                      dispatch(
                        setSelectedTemplate({
                          ...selectedTemplate,
                          content: {
                            ...selectedTemplate.content,
                            body:
                              selectedTemplate.content.body +
                              " " +
                              e.target.value,
                          },
                        })
                      );
                    } else {
                      const message = document.getElementById("message") as any;
                      if (message) {
                        const start = message.selectionStart;
                        const end = message.selectionEnd;
                        const text = message.value;
                        const before = text.substring(0, start);
                        const after = text.substring(end, text.length);
                        const selectedText = text.substring(start, end);
                        message.value = before + e.target.value + after;
                        message.selectionStart = start + e.target.value.length;
                        message.selectionEnd = start + e.target.value.length;
                        dispatch(
                          setSelectedTemplate({
                            ...selectedTemplate,
                            content: {
                              ...selectedTemplate.content,
                              body:
                                before + e.target.value + after + selectedText,
                            },
                          })
                        );
                      }
                    }
                  }}
                  name=""
                  id=""
                  className="p-2 border-2 border-mainColor/20  outline-none rounded-md"
                >
                  <option value="Insert" disabled selected>
                    Insert a variable
                  </option>
                  <option value="{{firstName}}">{`{{firstname}}`}</option>
                  <option value="{{lastName}}">{`{{lastname}}`}</option>

                  <option value="{{company}}">{`{{company}}`}</option>
                </select>
                <div className="flex gap-2">
                  <button
                    disabled={loading}
                    onClick={handleTemplateSave}
                    className=" border-2 border-mainColor  p-2 px-4 rounded-md font-semibold text-mainColor"
                  >
                    Save Template
                  </button>
                  <button
                    onClick={() => {
                      setShowNoteInput(false);
                      setSelectingActions(true);
                      setActionsAdded([...actionsAdded, "Invitation"]);
                      dispatch(
                        setNewCampaignData({
                          ...newCampaignData,
                          actions: [
                            ...newCampaignData.actions,
                            {
                              type: "invitation",
                              customMessage: selectedTemplate.content.body,
                            },
                          ],
                        })
                      );
                    }}
                    className="bg-mainColor text-white p-2 px-4 rounded-md font-semibold"
                  >
                    Add More
                  </button>
                </div>
              </div>
            </div>
            <div className="w-[30%] gap-2 flex flex-col px-4">
              <div className="border-2 flex w-full justify-start gap-4 font-semibold items-center text-white bg-mainColor p-4 rounded-md">
                <IoMdBulb size={30} />
                <span>Message Preview</span>
              </div>
              <div className="bg-primary w-full  border-mainColor/50 border-2 rounded-lg p-4 flex flex-col">
                <div className="flex w-full flex-col gap-4 items-start">
                  <div className="flex justify-center items-center gap-2">
                    <span className="p-1 text-mainColor bg-mainColor/20 rounded-lg">
                      <MdPerson size={30} />
                    </span>
                    <h1 className="font-semibold">John Doe</h1>
                  </div>
                  <div className="bg-white w-full h-full p-2 rounded-md text-sm">
                    <textarea
                      disabled
                      value={
                        selectedTemplate?.content.body
                          .replace("{{firstName}}", "John")
                          .replace("{{lastName}}", "Doe") || ""
                      }
                      placeholder="Subject"
                      className="outline-none resize-none bg-white  w-full h-32"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {addingDelay && (
          <div className="p-4 flex w-full flex-col justify-center items-start">
            <h1 className="text-xl font-semibold">Add a delay</h1>
            <div className="flex w-full gap-4 mt-4">
              <input
                value={delay}
                onChange={(e) => {
                  setDelay(e.target.value);
                }}
                type="number"
                min={1}
                max={20}
                placeholder="Delay in days"
                className="p-2 border-2 border-mainColor/20 w-[30%] outline-none rounded-md"
              />
              <button
                onClick={() => {
                  setAddingDelay(false);
                  setSelectingActions(true);
                  dispatch(
                    setNewCampaignData({
                      ...newCampaignData,
                      actions: [
                        ...newCampaignData.actions,
                        { type: "delay", delay: delay },
                      ],
                    })
                  );
                  setDelay("1");
                }}
                className="bg-mainColor text-white p-2 px-4 rounded-md font-semibold"
              >
                Add
              </button>
            </div>
            <div>
              <div className="border-2 flex w-full justify-start gap-4 font-semibold items-center text-sm mt-12 bg-mainColor/60 p-4 rounded-md">
                <FaLightbulb size={20} />
                <span>
                  <p>You can add a delay from 1 Day to 20 Days</p>
                  {/* <p>You can add more actions or start the campaign</p> */}
                </span>
              </div>
            </div>
          </div>
        )}

        {showConnectedModal && (
          <div className="absolute z-50 top-0 left-0 w-full h-full bg-black/50 flex justify-center items-center">
            <div className="bg-white w-[50%] h-[50%] p-4 flex flex-col gap-4 rounded-lg">
              <h1 className="text-xl font-semibold">
                Select connected prospects
              </h1>
              <div className="flex flex-col gap-4 max-h-full overflow-y-scroll noScroll">
                {connectedProspects.map((prospect: any) => (
                  <div
                    key={prospect._id}
                    className="flex justify-between items-center gap-4"
                  >
                    <div className="flex gap-4">
                      <img
                        src={prospect.image_url}
                        alt=""
                        className="w-10 h-10 rounded-full"
                      />
                      <div>
                        <h1 className="font-semibold">{prospect.full_name}</h1>
                        <p className="text-sm">{prospect.title}</p>
                      </div>
                    </div>
                    <button
                      onClick={() => {
                        setSelectedProspects([...selectedProspects, prospect]);
                        setConnectedProspects((prev) =>
                          prev.filter((p) => p._id !== prospect._id)
                        );
                      }}
                      className="bg-mainColor text-white p-2 rounded-lg"
                    >
                      Add
                    </button>
                  </div>
                ))}
              </div>
              <div className="flex w-full justify-center gap-4">
                <button
                  onClick={() => {
                    setShowConnectedModal(false);
                    setConnectedProspects(
                      newCampaignData.prospects.filter(
                        (prospect: any) => prospect.connected === "ACCEPTED"
                      )
                    );
                  }}
                  className="border-2 w-1/2 border-mainColor text-mainColor p-2 rounded-lg"
                >
                  Cancel
                </button>
                <button
                  onClick={() => {
                    setShowConnectedModal(false);
                    setActionsAdded([...actionsAdded, "Message"]);
                    setAddingMessage(true);
                    setSelectingActions(false);
                    dispatch(
                      setNewCampaignData({
                        ...newCampaignData,
                        prospects: connectedProspects,
                      })
                    );
                  }}
                  className="bg-mainColor w-1/2 text-white p-2 rounded-lg"
                >
                  Select All
                </button>
                <button
                  onClick={() => {
                    setShowConnectedModal(false);
                    setActionsAdded([...actionsAdded, "Message"]);
                    setAddingMessage(true);
                    setSelectingActions(false);
                    dispatch(
                      setNewCampaignData({
                        ...newCampaignData,
                        prospects: selectedProspects,
                      })
                    );
                  }}
                  className="bg-mainColor w-1/2 text-white p-2 rounded-lg"
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CreateModal;
