import axios from "./axios";

export const fetchOwner = async (ownerId: string) => {
  try {
    const response = await axios.post("/owner/getOwner", {
      ownerId,
    });

    return response.data;
  } catch (error) {
    throw new Error("Error fetching owner");
  }
};

function getDifferenceInDays(date1: Date, date2: Date): number {
  const msInOneDay = 24 * 60 * 60 * 1000; // Number of milliseconds in a day
  const diffInMs = Math.abs(date2.getTime() - date1.getTime()); // Difference in milliseconds
  return Math.floor(diffInMs / msInOneDay); // Convert milliseconds to days
}

export const checkPermission = async(ownerId: string) => {
  try {
    const response = await axios.post("/owner/getOwner", {
      ownerId,
    });

    const data = response.data;
    console.log(data);
    const planHistory = data.planhistory[data.planhistory.length-1];
    const premium = planHistory.isActive;

    return true;

    if(planHistory.planId == "FREE_PLAN"){
      if(getDifferenceInDays(new Date(planHistory.chargedAt.toString()), new Date()) > 7) return false;
      else return true;
    }else{
      return premium;
    }

  } catch (error) {
    throw new Error("Error fetching owner");
  }
}

export const fetchLists = async (ownerId: string, workspaceId: string) => {
  try {
    const response = await axios.post("/prospect/getProspectList", {
      ownerId,
      workspaceId,
    });

    return response.data;
  } catch (error) {
    console.log(error);
    throw new Error("Error fetching lists");
  }
};

export const fetchTemplates = async (ownerId: string) => {
  try {
    const response = await axios.post("/template/getTemplates", {
      ownerId,
    });

    return response.data;
  } catch (error) {
    console.log(error);
    throw new Error("Error fetching templates");
  }
};

export const fetchCampaigns = async (ownerId: string) => {
  try {
    const response = await axios.post("/campaign/getCampaigns", {
      ownerId,
    });

    return response.data.campaigns;
  } catch (error) {
    throw new Error("Error fetching campaigns");
  }
};

export const fetchTags = async (ownerId: string) => {
  try {
    const response = await axios.post("/tags/getTags", {
      ownerId,
    });

    return response.data;
  } catch (error) {
    throw new Error("Error fetching tags");
  }
};

export const fetchProspects = async (
  ownerId: string,
  listId: string,
  page: number,
  limit: number
) => {
  try {
    const response = await axios.post(
      `/prospect/getProspectsOfList?page=${page}&limit=${limit}`,
      {
        ownerId,
        listId,
      }
    );

    return response.data;
  } catch (error) {
    throw new Error("Error fetching prospects");
  }
};

export const fetchOwnerLinkedin = async (
  ownerId: string,
  profileUrn: string
) => {
  try {
    const response = await axios.post("/linkedin/profileView", {
      ownerId,
      profileUrn,
    });

    return response.data;
  } catch (error) {
    throw new Error("Error fetching owner linkedin");
  }
};

export const fetchOwnerConnections = async (ownerId: string) => {
  try {
    const response = await axios.post("/linkedin/getAllConnections", {
      ownerId,
    });

    return response.data.connections;
  } catch (error) {
    console.log(error);
    throw new Error("Error fetching owner connections");
  }
};

export const fetchAllRequests = async (ownerId: string, type: string) => {
  try {
    const response = await axios.post("/linkedin/getAllRequests", {
      ownerId,
      type,
    });

    return response.data;
  } catch (error) {
    console.log(error);
    throw new Error("Error fetching requests");
  }
};

export const deleteAllRequests = async (ownerId: string) => {
  try {
    const response = await axios.post("/linkedin/deleteAllRequests", {
      ownerId,
    });

    return response.data;
  } catch (error) {
    console.log(error);
    throw new Error("Error deleting requests");
  }
};

export const fetchTeamMembers = async (
  ownerId: string,
  workspaceId: string
) => {
  try {
    const response = await axios.post("/teams/getTeamMembers", {
      ownerId,
      workspaceId,
    });

    return response.data;
  } catch (error) {
    console.log(error);
    throw new Error("Error fetching team members");
  }
};
